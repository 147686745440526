import * as React from "react";
import { SelectOption } from "./select.types";
import { useClickOutside } from "react-click-outside-hook";

type Props = {
  value: number;
  options: SelectOption[];
  label: string;
  onChange: (id: number) => void;
};

const Select: React.FC<Props> = ({ value, options, label, onChange }) => {
  const [ref, hasClickedOutside] = useClickOutside();

  React.useEffect(() => {
    if (hasClickedOutside) {
      setOpened(false);
    }
  }, [hasClickedOutside]);

  const [opened, setOpened] = React.useState(false);
  const currentValue = React.useMemo(() => {
    return options.find((option) => option.id == value)?.name || " - ";
  }, [value, options]);

  const handleChange = React.useCallback(
    (id) => {
      onChange(id);
      setOpened(false);
    },
    [onChange, setOpened]
  );

  const renderOptions = React.useMemo(
    () => (
      <ul className="select__options">
        {options.map((option) => (
          <li
            className="select__option"
            key={option.id}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleChange(option.id);
            }}
          >
            {option.name}
          </li>
        ))}
      </ul>
    ),
    [options, opened]
  );

  return (
    <div
      className={`select ${opened ? "select--opened" : ""}`}
      onClick={(e) => {
        e.stopPropagation();
        setOpened(true);
      }}
      ref={ref}
    >
      <span className="select__label">{label}</span>
      <span className="select__value">{currentValue}</span>
      <div className="select__dropdown">{renderOptions}</div>
    </div>
  );
};

export { Select };
