import * as React from "react";
import * as ReactDOM from "react-dom";
import { Hub } from "./components/Hub";

const PDF_HUB_ID = "pdf-hub";
const PDF_HUB_MODAL = "pdf-hub-modal";

const loadHub = () => {
  const pdfHub: HTMLDivElement = document.createElement("div");
  pdfHub.setAttribute("id", PDF_HUB_ID);
  document.getElementById(PDF_HUB_MODAL).appendChild(pdfHub);
  ReactDOM.render(<Hub />, document.getElementById(PDF_HUB_ID));
};

const unloadHub = () => {
  document.getElementById(PDF_HUB_ID).remove();
};

document.addEventListener("DOMContentLoaded", () => {
  // @ts-ignore
  window.loadHub = loadHub;
  // @ts-ignore
  window.unloadHub = unloadHub;
});
