import * as React from "react";
import { Posts } from "./Posts";
import { Select } from "./Select";
// @ts-ignore
const numbers2words = require("numbers2words");
const translator = new numbers2words("EN_US");

const PDF_OPTIONS = {
  jsPDF: {
    orientation: "landscape",
    format: "a4",
    putOnlyUsedFonts: true,
    compress: false,
  },
  html2canvas: {
    scale: 2,
  },
};

const Hub = () => {
  const [infoVisible, setInfoVisible] = React.useState(false);
  const [selectedPosts, setSelectedPosts] = React.useState<string[]>([]);
  const [artists, setArtists] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [selectedArtist, setSelectedArtist] = React.useState(
    document.getElementById("show-pdf-hub").dataset.artist
  );
  const [selectedCategory, setSelectedCategory] = React.useState<number>(-1);

  React.useEffect(() => {
    fetchArtists();
  }, []);

  React.useEffect(() => {
    fetchCategories();
  }, [selectedArtist]);

  const fetchArtists = React.useCallback(() => {
    fetch(
      `${process.env.REACT_APP_HOST}?rest_route=/wp/v2/categories&parent=${process.env.REACT_APP_MAIN_CATEGORY}&per_page=100`
    ).then((response) => {
      response.json().then((data) => {
        const result = data.map((cat: any) => ({
          id: cat.id,
          name: cat.name,
        }));
        setArtists(result);
      });
    });
  }, [setArtists]);

  const fetchCategories = React.useCallback(() => {
    fetch(
      `${process.env.REACT_APP_HOST}?rest_route=/wp/v2/categories&parent=${process.env.REACT_APP_CATEGORY_ID}&per_page=100`
    ).then((response) => {
      response.json().then((data) => {
        const result = data.map((cat: any) => ({
          id: cat.id,
          name: cat.name,
        }));
        setCategories([{ id: -1, name: "Full portfolio" }, ...result]);
        document.getElementById("pdf-hub-modal").classList.remove("scrolled");
      });
    });
  }, [setCategories]);

  const handleChangeArtist = React.useCallback((id) => {
    setSelectedArtist(id);
    setSelectedCategory(-1);
    fetchCategories();
  }, []);

  const handleChangeCategory = React.useCallback((id) => {
    setSelectedCategory(id);
  }, []);

  const getCategories = React.useMemo(() => {
    return selectedCategory && selectedCategory !== -1
      ? [selectedCategory, selectedArtist]
      : [selectedArtist];
  }, [selectedCategory, categories, selectedArtist]);

  const getSelectedPostsNumber = React.useMemo(() => {
    const selectedPostsNumber = selectedPosts.length;
    if (selectedPostsNumber === 1) return `One story selected`;
    return `${translator.toWords(selectedPostsNumber)} stories selected`;
  }, [selectedPosts]);

  const handleSetSelectedPosts = (
    id: string,
    checked: boolean,
    selected: string[]
  ) => {
    let newSelectedPosts = [];
    if (checked) {
      newSelectedPosts = [...selected, id];
    } else {
      newSelectedPosts = selected.filter((p) => p !== id);
    }

    setSelectedPosts(newSelectedPosts);
  };

  const handleDownload = () => {
    setInfoVisible(true);
    const query = selectedPosts.reduce((prev, current, index) => {
      const queryMark = index === 0 ? "?" : "&";
      return `${prev}${queryMark}post_id[]=${current}`;
    }, "");
    fetch(`${process.env.REACT_APP_HOST}pdf/custom.php${query}`).then(
      (response) => {
        response.text().then((text) => {
          const doc = new DOMParser().parseFromString(text, "text/html");
          const styles = doc.head.getElementsByTagName('style');
          const pages = Array.from(doc.querySelectorAll('.page'));
          const pagesWithStyles = pages.map(p => {
            const page = document.createElement('div');
            page.appendChild(p);
            page.append(styles[0].cloneNode(true));
            return page;
          })
          // @ts-ignore
          let worker = html2pdf()
            .set(PDF_OPTIONS)
            .from(pagesWithStyles[0]).toPdf();
          pagesWithStyles.slice(1).forEach(page => {
            // @ts-ignore
            worker = worker.get('pdf').then(pdf => pdf.addPage()).from(page).toContainer().toCanvas().toPdf();
          });
          worker.save(`CREARTVT.pdf`)
            .then(() => {
              document
                .getElementById("pdf-hub-modal")
                .classList.remove("visible");
              document.body.classList.remove("is-locked");
              setInfoVisible(false);
              setTimeout(() => {
                // @ts-ignore
                window.unloadHub();
              }, 300);
            });
        });
      }
    );
  };

  const handleScroll = (e: any) => {
    const parent = document.getElementById("pdf-hub-modal");

    if (e.target.scrollTop > 50) {
      parent.classList.add("scrolled");
    } else {
      parent.classList.remove("scrolled");
    }
  };

  return (
    <div className="hub">
      <div className="hub__sidebar">
        <span className="hub__title">PDF Download Hub</span>
        <span className="hub__description">
          Customise your PDF download by using the filters below and selecting
          individual stories to add to your PDF.
        </span>
        <Select
          value={(selectedArtist as unknown) as number}
          options={artists}
          onChange={handleChangeArtist}
          label="Choose artist"
        />
        <Select
          value={selectedCategory}
          options={categories}
          onChange={handleChangeCategory}
          label="Choose category"
        />
        <span className="hub__selected-number">{getSelectedPostsNumber}</span>
        <button className="hub__button" onClick={handleDownload}>
          Download PDF
        </button>
        <span className={`hub__button-info ${infoVisible ? "visible" : ""}`}>
          Your download should begin in few seconds...
        </span>
      </div>
      <div className="hub__posts" onScroll={handleScroll}>
        <Posts
          categories={getCategories}
          selectedPosts={selectedPosts}
          setSelectedPosts={handleSetSelectedPosts}
        />
      </div>
    </div>
  );
};

export { Hub };
