import * as React from "react";
import { SinglePost } from "../SinglePost";

const noPostsMessage = document.getElementById("show-pdf-hub").dataset.noposts;

type Props = {
  categories: string[];
  setSelectedPosts: (id: string, checked: boolean, selected: string[]) => void;
  selectedPosts: string[];
};

export type Post = {
  id: string;
  title: string;
  image_id: number;
  artist: string;
};

const Posts: React.FC<Props> = ({
  categories,
  setSelectedPosts,
  selectedPosts,
}) => {
  const [posts, setPosts] = React.useState<Post[]>([]);
  const [images, setImages] = React.useState<{ [key: number]: string }>({});
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    categories.length && fetchPosts();
    () => {
      setLoading(false);
    };
  }, [categories]);

  const fetchPosts = React.useCallback(() => {
    setLoading(true);
    const query = `&categories=${categories.join(",")}&cat_relation=AND`;

    fetch(
      `${process.env.REACT_APP_HOST}?rest_route=/wp/v2/posts${query}&per_page=100`
    ).then((response) => {
      response.json().then((data) => {
        const result = data.map((post: any) => ({
          id: post.id,
          title: post.title.rendered,
          image_id: post.featured_media,
          artist: post.acf.artist[0].post_title,
        }));
        setPosts(result);
        fetchImages(result);
      });
    });
  }, [categories, images]);

  const fetchImages = React.useCallback(
    (posts) => {
      Promise.all(
        posts.map((post: Post) =>
          fetch(
            `${process.env.REACT_APP_HOST}?rest_route=/wp/v2/media/${post.image_id}`
          ).then((response) => response.json())
        )
      ).then((fetchedImages) => {
        const result = fetchedImages.reduce((res: object, image: any) => {
          // @ts-ignore
          res[image.id] = image.source_url;
          return res;
        }, {});
        // @ts-ignore
        setImages({ ...images, ...result });
        setLoading(false);
      });
    },
    [images]
  );

  const oddPosts = React.useMemo(() => posts.filter((_, i) => i % 2 === 0), [
    posts,
  ]);

  const evenPosts = React.useMemo(() => posts.filter((_, i) => i % 2 !== 0), [
    posts,
  ]);

  const renderSinglePost = React.useCallback(
    (post) => (
      <SinglePost
        key={post.id}
        post={post}
        image={images[post.image_id]}
        checked={Boolean(selectedPosts.find((p) => p == post.id))}
        onChange={setSelectedPosts}
        selectedPosts={selectedPosts}
      />
    ),
    [images, selectedPosts, posts, setSelectedPosts]
  );

  const renderPosts = React.useMemo(() => {
    return posts.length ? (
      <>
        <div className="posts__column">{oddPosts.map(renderSinglePost)}</div>
        <div className="posts__column">{evenPosts.map(renderSinglePost)}</div>
      </>
    ) : (
      noPostsMessage
    );
  }, [oddPosts, evenPosts, images, posts, selectedPosts]);

  return <div className="posts">{loading ? "Loading..." : renderPosts}</div>;
};

export { Posts };
