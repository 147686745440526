import * as React from "react";
import { Post } from "../Posts";

type Props = {
  image: string;
  onChange: (id: string, checked: boolean, selectedPosts: string[]) => void;
  checked: boolean;
  post: Post;
  selectedPosts: string[];
};

const SinglePost: React.FC<Props> = ({
  post,
  image,
  onChange,
  checked,
  selectedPosts,
}) => {
  const handleChange = React.useCallback(() => {
    onChange(post.id, !checked, selectedPosts);
  }, [post, checked, selectedPosts]);
  return (
    <div
      key={post.id}
      className={`post ${checked ? "post--checked" : ""}`}
      onClick={handleChange}
      style={{ display: "inline-flex" }}
    >
      <img className="post__image" width="50" src={image} />
      <span className="post__title">{post.title}</span>
      <span className="post__artist">{post.artist}</span>
      <span className="post__checkbox"></span>
    </div>
  );
};

export { SinglePost };
